import React from "react"
import { Grid, Row, Col } from "react-flexbox-grid"
import { graphql, Link } from "gatsby"
import Pagination from "../components/pagination"
import Layout from "../components/layout"
import SEO from "../components/seo"
import GridGenerator from "../components/gridGenerator"
import style from "./category.module.css"

const Blogs = ({ data, pageContext }) => {
  return (
    <Layout>
      <SEO title="Posts" />
      <Grid>
        <Row>
          <Col>
            <h2 className={style.pageTitle}>Latest {pageContext.title}s</h2>
          </Col>
        </Row>
      </Grid>

      <GridGenerator cols={2} rowClass={style.row} colClass={style.col}>
        {data.allWordpressPost.edges.map(({ node }) => (
          <div key={node.id} className={style.featureCard}>
            <Link
              to={`/${node.path}`}
              style={{
                display: "flex",
                color: "black",
                textDecoration: "none",
              }}
            >
              <div>
                <div className={style.cardContent}>
                  <h3
                    dangerouslySetInnerHTML={{ __html: node.title }}
                    style={{ marginBottom: 15 }}
                  />
                  <p className={style.postDate}>{node.date}</p>
                  <div dangerouslySetInnerHTML={{ __html: node.excerpt }} />
                  <Link to={`/${node.path}`} className={style.moreLink}>
                    Continue Reading →
                  </Link>
                </div>
              </div>
            </Link>
          </div>
        ))}
      </GridGenerator>
      <Pagination
        pageCount={pageContext.pageCount}
        currentPage={pageContext.currentPage}
        base={`/podcast`}
      />
    </Layout>
  )
}

export const query = graphql`
  query($skip: Int!, $limit: Int!) {
    allWordpressPost(
      sort: { fields: [date], order: DESC }
      limit: $limit
      skip: $skip
    ) {
      edges {
        node {
          id
          date(formatString: "MMMM Do, YYYY")
          title
          excerpt
          path
        }
      }
    }
  }
`

export default Blogs
